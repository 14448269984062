@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743602516151/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743602516151/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743602516151/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-adventures:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-small:before { content: "\EA05" }
.icon-bed:before { content: "\EA06" }
.icon-blog:before { content: "\EA07" }
.icon-boat:before { content: "\EA08" }
.icon-calendar:before { content: "\EA09" }
.icon-cart:before { content: "\EA0A" }
.icon-check:before { content: "\EA0B" }
.icon-close:before { content: "\EA0C" }
.icon-dive:before { content: "\EA0D" }
.icon-doc:before { content: "\EA0E" }
.icon-download:before { content: "\EA0F" }
.icon-elements:before { content: "\EA10" }
.icon-elevator:before { content: "\EA11" }
.icon-facebook-new:before { content: "\EA12" }
.icon-facebook:before { content: "\EA13" }
.icon-familien-skiregion:before { content: "\EA14" }
.icon-family:before { content: "\EA15" }
.icon-fb:before { content: "\EA16" }
.icon-filter:before { content: "\EA17" }
.icon-foerderband:before { content: "\EA18" }
.icon-gondel-groß:before { content: "\EA19" }
.icon-google:before { content: "\EA1A" }
.icon-hand:before { content: "\EA1B" }
.icon-hike:before { content: "\EA1C" }
.icon-in:before { content: "\EA1D" }
.icon-info:before { content: "\EA1E" }
.icon-insta:before { content: "\EA1F" }
.icon-instagram-new:before { content: "\EA20" }
.icon-interactive-card:before { content: "\EA21" }
.icon-jpg:before { content: "\EA22" }
.icon-language:before { content: "\EA23" }
.icon-linkedin:before { content: "\EA24" }
.icon-mail:before { content: "\EA25" }
.icon-marker:before { content: "\EA26" }
.icon-pdf:before { content: "\EA27" }
.icon-percentage:before { content: "\EA28" }
.icon-pinterest-new:before { content: "\EA29" }
.icon-pinterest:before { content: "\EA2A" }
.icon-play:before { content: "\EA2B" }
.icon-poi:before { content: "\EA2C" }
.icon-restaurant:before { content: "\EA2D" }
.icon-schlepplift:before { content: "\EA2E" }
.icon-search:before { content: "\EA2F" }
.icon-sessellift-1er:before { content: "\EA30" }
.icon-sessellift-2er:before { content: "\EA31" }
.icon-sessellift-3er:before { content: "\EA32" }
.icon-sessellift-4er:before { content: "\EA33" }
.icon-sessellift-6er:before { content: "\EA34" }
.icon-sessellift-8er:before { content: "\EA35" }
.icon-share:before { content: "\EA36" }
.icon-snow:before { content: "\EA37" }
.icon-sommerbahn:before { content: "\EA38" }
.icon-standseilbahn:before { content: "\EA39" }
.icon-swim:before { content: "\EA3A" }
.icon-tellerlift:before { content: "\EA3B" }
.icon-tiktok-new:before { content: "\EA3C" }
.icon-twitter:before { content: "\EA3D" }
.icon-upload:before { content: "\EA3E" }
.icon-weather-a:before { content: "\EA3F" }
.icon-weather-b:before { content: "\EA40" }
.icon-weather-c:before { content: "\EA41" }
.icon-weather-d:before { content: "\EA42" }
.icon-weather-e:before { content: "\EA43" }
.icon-weather-f:before { content: "\EA44" }
.icon-weather-g:before { content: "\EA45" }
.icon-weather-h:before { content: "\EA46" }
.icon-weather-i:before { content: "\EA47" }
.icon-weather-j:before { content: "\EA48" }
.icon-weather-k:before { content: "\EA49" }
.icon-weather-l:before { content: "\EA4A" }
.icon-weather-m:before { content: "\EA4B" }
.icon-weather-n:before { content: "\EA4C" }
.icon-weather-o:before { content: "\EA4D" }
.icon-weather-p:before { content: "\EA4E" }
.icon-weather-q:before { content: "\EA4F" }
.icon-weather-r:before { content: "\EA50" }
.icon-weather-s:before { content: "\EA51" }
.icon-weather-t:before { content: "\EA52" }
.icon-weather-u:before { content: "\EA53" }
.icon-weather-v:before { content: "\EA54" }
.icon-weather-w:before { content: "\EA55" }
.icon-weather-x:before { content: "\EA56" }
.icon-weather-y:before { content: "\EA57" }
.icon-weather-z:before { content: "\EA58" }
.icon-weather:before { content: "\EA59" }
.icon-webcams:before { content: "\EA5A" }
.icon-whatsapp:before { content: "\EA5B" }
.icon-youtube-new:before { content: "\EA5C" }
.icon-youtube:before { content: "\EA5D" }


:root {
--icon-addthis: "\EA01";
    --icon-adventures: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow-small: "\EA05";
    --icon-bed: "\EA06";
    --icon-blog: "\EA07";
    --icon-boat: "\EA08";
    --icon-calendar: "\EA09";
    --icon-cart: "\EA0A";
    --icon-check: "\EA0B";
    --icon-close: "\EA0C";
    --icon-dive: "\EA0D";
    --icon-doc: "\EA0E";
    --icon-download: "\EA0F";
    --icon-elements: "\EA10";
    --icon-elevator: "\EA11";
    --icon-facebook-new: "\EA12";
    --icon-facebook: "\EA13";
    --icon-familien-skiregion: "\EA14";
    --icon-family: "\EA15";
    --icon-fb: "\EA16";
    --icon-filter: "\EA17";
    --icon-foerderband: "\EA18";
    --icon-gondel-groß: "\EA19";
    --icon-google: "\EA1A";
    --icon-hand: "\EA1B";
    --icon-hike: "\EA1C";
    --icon-in: "\EA1D";
    --icon-info: "\EA1E";
    --icon-insta: "\EA1F";
    --icon-instagram-new: "\EA20";
    --icon-interactive-card: "\EA21";
    --icon-jpg: "\EA22";
    --icon-language: "\EA23";
    --icon-linkedin: "\EA24";
    --icon-mail: "\EA25";
    --icon-marker: "\EA26";
    --icon-pdf: "\EA27";
    --icon-percentage: "\EA28";
    --icon-pinterest-new: "\EA29";
    --icon-pinterest: "\EA2A";
    --icon-play: "\EA2B";
    --icon-poi: "\EA2C";
    --icon-restaurant: "\EA2D";
    --icon-schlepplift: "\EA2E";
    --icon-search: "\EA2F";
    --icon-sessellift-1er: "\EA30";
    --icon-sessellift-2er: "\EA31";
    --icon-sessellift-3er: "\EA32";
    --icon-sessellift-4er: "\EA33";
    --icon-sessellift-6er: "\EA34";
    --icon-sessellift-8er: "\EA35";
    --icon-share: "\EA36";
    --icon-snow: "\EA37";
    --icon-sommerbahn: "\EA38";
    --icon-standseilbahn: "\EA39";
    --icon-swim: "\EA3A";
    --icon-tellerlift: "\EA3B";
    --icon-tiktok-new: "\EA3C";
    --icon-twitter: "\EA3D";
    --icon-upload: "\EA3E";
    --icon-weather-a: "\EA3F";
    --icon-weather-b: "\EA40";
    --icon-weather-c: "\EA41";
    --icon-weather-d: "\EA42";
    --icon-weather-e: "\EA43";
    --icon-weather-f: "\EA44";
    --icon-weather-g: "\EA45";
    --icon-weather-h: "\EA46";
    --icon-weather-i: "\EA47";
    --icon-weather-j: "\EA48";
    --icon-weather-k: "\EA49";
    --icon-weather-l: "\EA4A";
    --icon-weather-m: "\EA4B";
    --icon-weather-n: "\EA4C";
    --icon-weather-o: "\EA4D";
    --icon-weather-p: "\EA4E";
    --icon-weather-q: "\EA4F";
    --icon-weather-r: "\EA50";
    --icon-weather-s: "\EA51";
    --icon-weather-t: "\EA52";
    --icon-weather-u: "\EA53";
    --icon-weather-v: "\EA54";
    --icon-weather-w: "\EA55";
    --icon-weather-x: "\EA56";
    --icon-weather-y: "\EA57";
    --icon-weather-z: "\EA58";
    --icon-weather: "\EA59";
    --icon-webcams: "\EA5A";
    --icon-whatsapp: "\EA5B";
    --icon-youtube-new: "\EA5C";
    --icon-youtube: "\EA5D";
    
}